import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout/Layout.component";
import Header from "../components/header/Header.component";
import Section from "../components/layout/section/Section.component";

const AboutPage = () => {
  const page = {
    introduction: "About",
    header: "My name is Bartek",
    description:
      "I'm a skilled and highly qualified developer who works in several different programming languages. I'm results-oriented, hard-working, and never give up until the job is done. I'm also a very fun person to be around! I build beautiful websites and applications. I also provide web design and development for small businesses that need a little help - no project is too big or too small! ",
  };

  return (
    <Layout>
      <Header page={page} />
      <Section title="My experience includes">
        <StaticImage src="../images/const.png" as="div" />
      </Section>
    </Layout>
  );
};
export default AboutPage;

export function Head() {
  return <title>Bartłomiej Nowak - frontend developer | About</title>;
}
